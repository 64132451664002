import '../../styles/pages/fall-winter-2021/9-projects-transforming-the-campus.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import MoreStories from "../../components/fall-winter-2021-stories/more-stories"
import ReactFullpage from '@fullpage/react-fullpage';
import Icon from "../../components/icons"
import { Preloader, Placeholder } from 'react-preloading-screen';
import { SwishSpinner } from "react-spinners-kit";
// import Modal from 'react-modal';
import { withPrefix } from 'gatsby'
import { Scrollbars } from 'react-custom-scrollbars';
// import Footer from '../../components/footer';

import socialBanner from "../../images/fall-winter-2021/social-banners/9-projects-transforming-the-campus.jpg";


const pluginWrapper = () => {
  require('../../scripts/fullpage.responsiveSlides.min.js');
  // require('../../scripts/fullpage.scrollHorizontally.min.js');
};

export default class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      headerHide: false
    };
  }

  render() {
    var pageTitle = '9 Projects Transforming the Campus';
    var pageDesc = 'Advancing the learning environment prepares Titans for a rapidly changing world.';
    var slug = '9-projects-transforming-the-campus';

    return (
      <Layout locationInfo={this.props.location} headerHide={this.state.headerHide} issue="fall-winter-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="projects-transforming-the-campus" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <Preloader fadeDuration={500} style={{backgroundColor: '#111'}}>

        <ReactFullpage
          id="story-content"
          licenseKey={'74BC1699-BD29452E-B9391E28-1CBD2120'}
          // menu="#menu"
          pluginWrapper={pluginWrapper}
          normalScrollElements={'#mainNav, .text-container'}
          loopBottom={false}
          navigation={false}
          slidesNavigation={false}
          controlArrows={false}
          responsiveSlides={true}
          responsiveSlidesKey={'ZnVsbGVydG9uLmVkdV85MzFjbVZ6Y0c5dWMybDJaVk5zYVdSbGN3PT00Zkk='}
          onLeave ={(origin, destination, direction) => {

            if (direction === 'down') {
              this.setState({headerHide: true});
            } else {
              this.setState({headerHide: false});
            }

          }}
          responsiveWidth={'769'}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section hero-section fp-auto-height-responsive">
                  <div className="hero-text">
                    
                    <h1 className="animated fadeInUp slower">{pageTitle}</h1>
                    <span className="sub-title animated fadeInUp slower">{pageDesc}</span>
                    <hr className="animated fadeInUp slower" />
                    <span className="byline animated fadeInUp slower">By Kendra Morales, Photos by Matt Gush</span>

                    <p className="leadin animated fadeIn slower delay-1s"><span className="intro-text">Nationally recognized teaching and student success programs deserve a cutting-edge learning environment.</span> Cal State Fullerton is elevating its infrastructure and technology to ensure students are equipped to lead in the workforce and community. A six-story parking structure, new student housing units, upgraded Visual Arts Complex, and modernized baseball and softball facilities are among the projects planned through 2024.</p>

                    <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down animated fadeIn slower delay-1s">
                      <Icon name="arrow-down" alt="Read Story" class="lg" />
                    </button>

                  </div>
                  <video id="heroVideo" data-autoplay autoPlay="autoplay" loop muted playsInline>
                    <source src={withPrefix('/video/fall-winter-2021/9-projects-transforming-the-campus-loop.webm')} type="video/webm; codecs=vp9,vorbis" />
                    <source src={withPrefix('/video/fall-winter-2021/9-projects-transforming-the-campus-loop.mp4')} type="video/mp4" />
                    <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/fall-winter-2021/9-projects-transforming-the-campus-loop.vtt')} />
                  </video>
                </div>
                <div className="section section1 fp-auto-height-responsive" id="library-renovations-and-safety-improvements">
                  <div className="mobile-image" />  
                  <div className="text-container">

                    <Scrollbars
                      className="scrollbars"
                      renderView={props => <div {...props} className="scrollbar-view"/>}
                      renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                      
                      style={{ width: '100%', height: '95%' }}>
            
                        <h2>Library Renovations and Safety Improvements</h2>

                        <p><strong>Project:</strong> Renovations on the basement, fourth, fifth and sixth floors on the south side of Pollak Library</p>

                        <p><strong>Budget:</strong> $4.8 million for fourth and fifth floor renovation; $6 million for sixth floor renovation; $11.75 million for fire/life safety improvements</p>

                        <p><strong>Details:</strong></p>
                        <ul>
                          <li>Updated flooring, lighting, plumbing, HVAC equipment and restrooms</li>
                          <li>378 additional classroom seats on the fourth and fifth floors</li>
                          <li>70 ventilation windows on the second through sixth floors</li>
                          <li>A modern fire lane</li>
                          <li>Relocation of the <a href="https://coph.fullerton.edu" target="_blank">Lawrence de Graaf Center for Oral and Public History</a> to sixth floor</li>
                          <li>Fresh paint on exterior walls</li>
                        </ul>

                        <p><strong>Why it matters:</strong> The renovation improved building safety features, modernized spaces and provided much needed individual and group-study spaces.</p>

                        <p><strong>Status:</strong> Fire/life safety improvements, ventilation windows were completed in 2020. The fourth, fifth and sixth floors of the library opened in March 2021. Exterior walls are scheduled to be painted by the fall 2021 semester. </p>

                        <p><strong>Related story:</strong> <a href="http://news.fullerton.edu/2021/04/center-for-oral-and-public-historys-new-space-preserves-stories-for-future-generations/" target="_blank" rel="noreferrer">Center for Oral and Public History’s New Space Preserves Stories for Future Generations</a></p>

                    </Scrollbars>
                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                  <div className="image" />
                  
                </div>
                <div className="section section2 fp-auto-height-responsive" id="modernized-student-services-centers">
                  <div className="mobile-image" />
                  <div className="text-container">

                    <Scrollbars
                    className="scrollbars"
                    renderView={props => <div {...props} className="scrollbar-view"/>}
                      renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                      style={{ width: '100%', height: '95%' }}>
                        <h2>Modernized Student Services Centers</h2>

                        <p><strong>Projects:</strong> Athletics Academic Services Renovation; Admissions and Records Student Service Center Improvements</p>

                        <p><strong>Details:</strong></p>
                        
                        <ul>
                          <li>Renovated interior of the Athletic Academic space in Langsdorf Hall</li>
                          <li>New paint, flooring and furniture for the Admissions and Records Student Service Center</li>
                        </ul>

                        <p><strong>Why it matters:</strong> The modernized facilities in Langsdorf Hall improve the efficiency of the space to serve current and prospective students.</p>

                        <p><strong>Status:</strong> Completed</p>

                      </Scrollbars>

                    <div className="nav-controls">
                    <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                      <Icon name="arrow-up" alt="Previous Slide" />
                    </button>
                    <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                      <Icon name="arrow-down" alt="Next Slide" />
                    </button>
                  </div>

                  </div>
                  <div className="image" />
                </div>
                <div className="section section3 fp-auto-height-responsive" id="enhanced-space-for-mc-carthy-hall-classrooms">
                  <div className="mobile-image" />
                  <div className="text-container">

                  <Scrollbars
                    className="scrollbars"
                    renderView={props => <div {...props} className="scrollbar-view"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    style={{ width: '100%', height: '95%' }}>
                      <h2>Enhanced Space for McCarthy Hall Classrooms</h2>

                      <p><strong>Project:</strong> McCarthy Hall’s Second Floor Project</p>

                      <p><strong>Budget:</strong> $40 million</p>

                      <p><strong>Details:</strong></p>
                      <ul>
                        <li>About 13,500 square feet of additional lecture and classroom space</li>
                        <li>Lab space to serve as surge space</li>
                        <li>Elevator safety improvements</li>
                        <li>New landscaping and outdoor furniture</li>
                      </ul>

                      <p><strong>Why it matters:</strong> A fully renovated second floor provides enhanced space for learning and collaboration.</p>

                      <p><strong>Status:</strong> Construction will be completed during the fall 2021 semester. The second floor will be ready for occupancy in spring 2022.</p>

                      <p><strong>Related story:</strong> <a href="http://news.fullerton.edu/2021/02/mccarthy-hall-modernization-project-to-put-science-on-display/" target="_blank" rel="noreferrer">McCarthy Hall Modernization Project to Put ‘Science on Display’</a></p>

                    </Scrollbars>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                  <div className="image" />
                </div>
                <div className="section section4 fp-auto-height-responsive" id="updated-visual-arts-complex">
                  <div className="mobile-image" />
                  <div className="image" />
                  <div className="text-container">

                  <Scrollbars
                    className="scrollbars"
                    renderView={props => <div {...props} className="scrollbar-view"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    style={{ width: '100%', height: '95%' }}>
                      <h2>Updated Visual Arts Complex</h2>

                      <p><strong>Project:</strong>  Visual Arts Complex Modernization and Expansion</p>

                      <p><strong>Budget:</strong> $66 million</p>

                      <p><strong>Details:</strong></p>
                      <ul>
                        <li>Full renovation of Building E</li>
                        <li>Replacement of Buildings D and F (built in 1969) with new Buildings G and H</li>
                        <li>Correction of building deficiencies</li>
                        <li>Added gallery space in Building G</li>
                      </ul>

                      <p><strong>Why it matters:</strong> A new visual arts complex will improve the learning experience for students and attract more community members to the campus. Programs and events will be enhanced by state-of-the-art facilities and technology, allowing for better teaching and engagement within a range of art disciplines.</p>

                      <p><strong>Status:</strong> Expected to be completed in December 2024</p>

                    </Scrollbars>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section5 fp-auto-height-responsive">
                  <div className="mobile-image" />
                  <div className="text-container" id="olympic-size-competition-pool">

                  <Scrollbars
                    className="scrollbars"
                    renderView={props => <div {...props} className="scrollbar-view"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    style={{ width: '100%', height: '95%' }}>
                      <h2>Olympic-Size Competition Pool</h2>

                      <p><strong>Project:</strong> Kinesiology and Health Science pool improvements</p>

                      <p><strong>Budget:</strong> About $8 million</p>

                      <p><strong>Details:</strong></p>
                      <ul>
                        <li>An Olympic-size, 50-meter-long competition pool with nine lanes</li>
                        <li>New pump equipment, chemical feeder, heating equipment and site work</li>
                        <li>Enhanced lighting and movable bulkhead </li>
                      </ul>

                      <p><strong>Why it matters:</strong> Pool improvements address structural deficiencies of the existing pools and provide modern facilities for the campus.</p>

                      <p><strong>Status:</strong> Expected to be completed in March 2022</p>

                    </Scrollbars>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                  <div className="image" />
                </div>
                <div className="section section6 fp-auto-height-responsive" id="baseball-softball-facilities-upgrades">
                  <div className="mobile-image" />
                  <div className="image" />
                  <div className="text-container">

                  <Scrollbars
                    className="scrollbars"
                    renderView={props => <div {...props} className="scrollbar-view"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    style={{ width: '100%', height: '95%' }}>
                      <h2>Baseball/Softball Facilities Upgrades</h2>

                      <p><strong>Budget:</strong> $12 million</p>

                      <p><strong>Details:</strong></p>
                      <ul>
                        <li>New two-story building for softball and baseball programs</li>
                        <li>Shared space, including a ticket booth and a large conference room for all campus athletics</li>
                        <li>Video-viewing room</li>
                        <li>Offices and administrative spaces</li>
                        <li>Expanded locker room</li>
                      </ul>
                      
                      <p><strong>Why it matters:</strong> Expansion and upgrades to men’s baseball and women’s softball team facilities will reduce overcrowding, correct functional deficiencies and improve equity between men’s and women’s programs.</p>

                      <p><strong>Status:</strong> Expected to be completed in December 2021</p>

                      <p><strong>Related stories:</strong></p>
                      <ul>
                        <li><a href="http://titanmag.fullerton.edu/spring-2020/one-pitch-at-a-time/">One Pitch at a Time</a></li>
                        <li><a href="http://titanmag.fullerton.edu/spring-2020/softball-clubhouse-makeover/">Softball On Deck for a Game-Changing Clubhouse</a></li>
                        <li><a href="http://news.fullerton.edu/2021/04/softball-dad-hits-it-out-of-the-park-with-gift-to-championship-program/" target="_blank" rel="noreferrer">‘Softball Dad’ Hits it Out of the Park With Gift to Championship Program</a></li>
                      </ul>
                      

                    </Scrollbars>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section7 fp-auto-height-responsive" id="expansion-of-student-housing">
                  <div className="mobile-image" />
                  <div className="text-container">

                  <Scrollbars
                    className="scrollbars"
                    renderView={props => <div {...props} className="scrollbar-view"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    style={{ width: '100%', height: '95%' }}>
                      <h2>Expansion of Student Housing</h2>

                      <p><strong>Project:</strong> Construction of a new housing community in what was formerly Parking Lot E</p>

                      <p><strong>Details:</strong></p>
                      <ul>
                        <li>182,000-square-foot complex</li>
                        <li>Housing for 600 sophomore and junior students</li>
                        <li>Six-story building with four-bedroom, two-bath suites</li>
                        <li>Student lounge, multi-purpose room, grad-staff apartments, laundry room, workspaces and meeting rooms, campus safety office<br /></li>
                        <li>New LED outdoor lighting</li>
                      </ul>

                      <p><strong>Why it matters:</strong> The new student housing community will replace an original community that was built more than three decades ago. The new facility will have about 200 more beds to meet growing demand and provide a safe, sustainable and maintenance-free environment.</p>

                      <p><strong>Status:</strong> Construction is on track for occupancy in August 2022</p>

                      <p><strong>Related story:</strong> <a href="http://news.fullerton.edu/2021/05/new-student-housing-on-campus/" target="_blank" rel="noreferrer">New Student Housing on Campus</a></p>

                    </Scrollbars>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                  <div className="image" />
                </div>
                <div className="section section8 fp-auto-height-responsive" id="improved-outdoor-areas-and-sustainability">
                  <div className="mobile-image" />
                  <div className="image" />
                  <div className="text-container">

                  <Scrollbars
                    className="scrollbars"
                    renderView={props => <div {...props} className="scrollbar-view"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    style={{ width: '100%', height: '95%' }}>
                      <h2>Improved Outdoor Areas and Sustainability</h2>

                      <p><strong>Budget:</strong> Varied</p>

                      <p><strong>Details:</strong></p>
                      <ul>
                        <li>Several landscaping beautification projects have taken place outside of the Humanities and Social Sciences and Education Classroom buildings, Clayes Performing Arts Center, Titan Stadium, Student Health Center, and Kinesiology and Health Science gym </li>
                        <li>24 touch-free water bottle filling stations</li>
                        <li>Seven dual-bin solar waste compactors</li>
                        <li>2-mile walking path on campus</li>
                      </ul>

                      <p><strong>Why it matters:</strong> The landscaping projects and new furniture brighten campus and enhance outdoor areas, while the water bottle filling stations, increased waste capacity and new walking path promote a healthier lifestyle for the CSUF community.</p>

                      <p><strong>Status:</strong> Ongoing during the fall 2021 semester</p>

                    </Scrollbars>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section9 fp-auto-height-responsive" id="added-parking-space">
                  <div className="mobile-image" />
                  <div className="text-container">

                  <Scrollbars
                    className="scrollbars"
                    renderView={props => <div {...props} className="scrollbar-view"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    style={{ width: '100%', height: '95%' }}>
                      <h2>Added Parking Space</h2>

                      <p><strong>Project:</strong> Eastside Parking Structure Two, located north of the existing Eastside Parking Structure</p>

                      <p><strong>Budget:</strong> $38.9 million</p>

                      <p><strong>Details:</strong></p>
                      <ul>
                        <li>Six stories</li>
                        <li>1,900 additional parking spaces</li>
                      </ul>

                      <p><strong>Why it matters:</strong> Along with adding parking spaces, improvements were made to East Campus Drive. A solar canopy, energy-efficient LED lighting, elevators and staircases were added to the Eastside Parking Structure.</p>

                      <p><strong>Status:</strong> Opened in September 2020</p>

                      <p><strong>Related story:</strong> <a href="http://news.fullerton.edu/2019/04/eastside-parking-structure-2/" target="_blank" rel="noreferrer">Eastside Parking to be Expanded With Additional Structure</a></p>


                    </Scrollbars>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                  <div className="image" />
                </div>
                <div className="section section-end">
                <p>
                  <em>Support campus transformation projects at Cal State Fullerton<br /><a href="http://campaign.fullerton.edu/campus-transformation">campaign.fullerton.edu/campus-transformation</a></em>
                </p>
                </div>
                <div className="section fp-auto-height">
                  <MoreStories slug={slug} />
                </div>
                {/* <div className="section fp-auto-height">
                  <Footer />
                </div> */}
              </ReactFullpage.Wrapper>
            );
          }}
        />
        
				<Placeholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <SwishSpinner size="30" frontColor="#dc8218" backColor="#00aeff" />
            <span style={{
              fontSize: '.7em',
              display: 'block', 
              marginTop: '30px', 
              color: '#ccc', 
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '5px'
            }}>
              Loading
            </span>
          </div>
				</Placeholder>
			</Preloader>

      </Layout>
    );
  }
} 